var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import { HelperService } from "../shared/helper.service";
import { NotificationService } from "../shared/notification.service";
import { LogService } from "../shared/log.service";
import { Router } from "@angular/router";
import { HttpClient } from "@angular/common/http";
import { switchMap, map } from "rxjs/operators";
import { environment } from "src/environments/environment";
import { ERROR_MESSAGE_DICTIONARY } from "../shared/error-message";
import { Subject } from "rxjs";
import { CompanyService } from "../company/company.service";
import * as i0 from "@angular/core";
import * as i1 from "../shared/helper.service";
import * as i2 from "../shared/notification.service";
import * as i3 from "../shared/log.service";
import * as i4 from "@angular/router";
import * as i5 from "@angular/common/http";
import * as i6 from "../company/company.service";
var UserService = /** @class */ (function () {
    function UserService(helpService, notification, logger, router, http, companyService) {
        this.helpService = helpService;
        this.notification = notification;
        this.logger = logger;
        this.router = router;
        this.http = http;
        this.companyService = companyService;
        this.isLoadingListener = new Subject();
        this.apiUrl = environment.apiUrl;
        this.staffUpdate = new Subject();
    }
    UserService.prototype.getIsLoadingListener = function () {
        return this.isLoadingListener.asObservable();
    };
    UserService.prototype.addProxy = function (payload) {
        return this.http.post(this.apiUrl + "app/delivery-proxy/", payload);
    };
    UserService.prototype.createBank = function (registerInfo /*AuthSignUp*/) {
        var _this = this;
        this.isUploading = true;
        this.isLoadingListener.next(true);
        var loginInfo = {
            username: this.helpService.lowerCase(registerInfo.username),
            password: registerInfo.password,
        };
        var regInfo = {
            username: this.helpService.lowerCase(registerInfo.username),
            first_name: this.helpService.firstUpper(registerInfo.first_name),
            last_name: this.helpService.upperCase(registerInfo.last_name),
            email: this.helpService.lowerCase(registerInfo.email),
            password: registerInfo.password,
            groups: [],
        };
        var updateInfo = {
            sex: registerInfo.sex,
            phone: registerInfo.phone,
            address: registerInfo.address,
            birth_date: null,
            // birth_date: this.helpService.formatDate(registerInfo.birth_date),
            // bank_code: "access",
            bank_code: registerInfo.bank_code,
            assigned_courier: registerInfo.assigned_courier,
            city: registerInfo.city,
            country: registerInfo.country,
            postal_code: registerInfo.postal_code,
            account_number: registerInfo.account_number,
            account_code: registerInfo.account_code,
            // user_type: registerInfo.user_type,
            user_type: "BNK",
            profile_status: true,
        };
        var shipper_info = {
            value: {
                sms_retrial_limit: registerInfo.sms_retrial_limit,
                allow_job_upload: registerInfo.allow_job_upload,
                allow_delivery_via_proxy: registerInfo.allow_delivery_via_proxy,
                name: registerInfo.first_name + " " + registerInfo.last_name,
                email: registerInfo.email,
                phone: registerInfo.phone,
                address: registerInfo.address,
                city: registerInfo.city,
                country_code: registerInfo.country_code,
                bank_code: registerInfo.bank_code,
            },
        };
        this.http
            .post(this.apiUrl + "auth/bank/register/", regInfo)
            // .subscribe((res) => {
            //   // console.log(res);
            // });
            .pipe(switchMap(function (res) {
            // console.log(res);
            _this.id = res.id;
            return _this.http.patch(_this.apiUrl + "app/users/" + _this.id + "/", updateInfo);
        }))
            .subscribe(function (res) {
            _this.isUploading = false;
            _this.isLoadingListener.next(false);
            // console.log(res);
            _this.companyService.createCompany(shipper_info);
            _this.notification.success(":: Succesfully Created account, Username: " + regInfo.username + ", password: " + regInfo.password);
            // this.login(loginInfo);
            _this.router.navigate(["/dashboard"]);
            _this.isUploading = false;
            // registerInfo.reset();
            // this.router.navigate(["complete-registration/"]);
        }, function (err) {
            switch (err.status) {
                case 400: {
                    _this.notification.warn(ERROR_MESSAGE_DICTIONARY.createUserE400);
                    break;
                }
                case 401: {
                    _this.notification.warn(ERROR_MESSAGE_DICTIONARY.createUserE401);
                    break;
                }
                case 403: {
                    _this.notification.warn(ERROR_MESSAGE_DICTIONARY.createUserE403);
                    break;
                }
                case 404: {
                    _this.notification.warn(ERROR_MESSAGE_DICTIONARY.createUserE404);
                    break;
                }
                case 415: {
                    _this.notification.warn(ERROR_MESSAGE_DICTIONARY.createUserE415);
                    break;
                }
                case 500: {
                    _this.notification.warn(ERROR_MESSAGE_DICTIONARY.e500);
                    break;
                }
                case 503: {
                    _this.notification.warn(ERROR_MESSAGE_DICTIONARY.e503);
                    break;
                }
            }
            _this.isUploading = false;
        });
    };
    UserService.prototype.createBankStaff = function (registerInfo /*AuthSignUp*/) {
        var _this = this;
        this.isUploading = true;
        this.isLoadingListener.next(true);
        var loginInfo = {
            username: this.helpService.lowerCase(registerInfo.username),
            password: registerInfo.password,
        };
        var regInfo = {
            username: this.helpService.lowerCase(registerInfo.username),
            first_name: this.helpService.firstUpper(registerInfo.first_name),
            last_name: this.helpService.upperCase(registerInfo.last_name),
            email: this.helpService.lowerCase(registerInfo.email),
            password: registerInfo.password,
            groups: [],
        };
        var updateInfo = {
            sex: registerInfo.sex,
            phone: registerInfo.phone,
            address: registerInfo.address,
            birth_date: null,
            // birth_date: this.helpService.formatDate(registerInfo.birth_date),
            // bank_code: "access",
            bank_code: registerInfo.bank_code,
            city: registerInfo.city,
            country: registerInfo.country,
            bank_staff_position: registerInfo.bank_staff_position,
            postal_code: registerInfo.postal_code,
            account_number: registerInfo.account_number,
            account_code: registerInfo.account_code,
            // user_type: registerInfo.user_type,
            user_type: "BNK",
            profile_status: true,
        };
        // console.log({ ...regInfo, ...updateInfo });
        this.http
            .post(this.apiUrl + "auth/bankstaff/register/", regInfo)
            // .subscribe((res) => {
            //   // console.log(res);
            // });
            .pipe(switchMap(function (res) {
            // console.log(res);
            _this.id = res.id;
            return _this.http.patch(_this.apiUrl + "app/users/" + _this.id + "/", updateInfo);
        }))
            .subscribe(function (res) {
            _this.isUploading = false;
            _this.isLoadingListener.next(false);
            // console.log(res);
            _this.notification.success(":: Succesfully Created account");
            // this.login(loginInfo);
            _this.router.navigate(["/dashboard"]);
            _this.isUploading = false;
            // registerInfo.reset();
            // this.router.navigate(["complete-registration/"]);
        }, function (err) {
            switch (err.status) {
                case 400: {
                    _this.notification.warn(ERROR_MESSAGE_DICTIONARY.createUserE400);
                    break;
                }
                case 401: {
                    _this.notification.warn(ERROR_MESSAGE_DICTIONARY.createUserE401);
                    break;
                }
                case 403: {
                    _this.notification.warn(ERROR_MESSAGE_DICTIONARY.createUserE403);
                    break;
                }
                case 404: {
                    _this.notification.warn(ERROR_MESSAGE_DICTIONARY.createUserE404);
                    break;
                }
                case 415: {
                    _this.notification.warn(ERROR_MESSAGE_DICTIONARY.createUserE415);
                    break;
                }
                case 500: {
                    _this.notification.warn(ERROR_MESSAGE_DICTIONARY.e500);
                    break;
                }
                case 503: {
                    _this.notification.warn(ERROR_MESSAGE_DICTIONARY.e503);
                    break;
                }
            }
            _this.isUploading = false;
        });
    };
    UserService.prototype.createSidMgt = function (registerInfo /*AuthSignUp*/) {
        var _this = this;
        this.isUploading = true;
        this.isLoadingListener.next(true);
        var loginInfo = {
            username: this.helpService.lowerCase(registerInfo.username),
            password: registerInfo.password,
        };
        var regInfo = {
            username: this.helpService.lowerCase(registerInfo.username),
            first_name: this.helpService.firstUpper(registerInfo.first_name),
            last_name: this.helpService.upperCase(registerInfo.last_name),
            email: this.helpService.lowerCase(registerInfo.email),
            password: registerInfo.password,
            groups: [],
        };
        var updateInfo = {
            sex: registerInfo.sex,
            phone: registerInfo.phone,
            address: registerInfo.address,
            birth_date: null,
            // birth_date: this.helpService.formatDate(registerInfo.birth_date),
            // bank_code: "access",
            bank_code: registerInfo.bank_code,
            city: registerInfo.city,
            country: registerInfo.country,
            postal_code: registerInfo.postal_code,
            account_number: registerInfo.account_number,
            account_code: registerInfo.account_code,
            // user_type: registerInfo.user_type,
            user_type: "BNK",
            profile_status: true,
        };
        // console.log({ ...regInfo, ...updateInfo });
        this.http
            .post(this.apiUrl + "auth/sid_mgt/register/", regInfo)
            // .subscribe((res) => {
            //   // console.log(res);
            // });
            .pipe(switchMap(function (res) {
            // console.log(res);
            _this.id = res.id;
            return _this.http.patch(_this.apiUrl + "app/users/" + _this.id + "/", updateInfo);
        }))
            .subscribe(function (res) {
            _this.isUploading = false;
            _this.isLoadingListener.next(false);
            // console.log(res);
            _this.notification.success(":: Succesfully Created account");
            // this.login(loginInfo);
            _this.router.navigate(["/dashboard"]);
            _this.isUploading = false;
            // registerInfo.reset();
            // this.router.navigate(["complete-registration/"]);
        }, function (err) {
            switch (err.status) {
                case 400: {
                    _this.notification.warn(ERROR_MESSAGE_DICTIONARY.createUserE400);
                    break;
                }
                case 401: {
                    _this.notification.warn(ERROR_MESSAGE_DICTIONARY.createUserE401);
                    break;
                }
                case 403: {
                    _this.notification.warn(ERROR_MESSAGE_DICTIONARY.createUserE403);
                    break;
                }
                case 404: {
                    _this.notification.warn(ERROR_MESSAGE_DICTIONARY.createUserE404);
                    break;
                }
                case 415: {
                    _this.notification.warn(ERROR_MESSAGE_DICTIONARY.createUserE415);
                    break;
                }
                case 500: {
                    _this.notification.warn(ERROR_MESSAGE_DICTIONARY.e500);
                    break;
                }
                case 503: {
                    _this.notification.warn(ERROR_MESSAGE_DICTIONARY.e503);
                    break;
                }
            }
            _this.isUploading = false;
        });
    };
    UserService.prototype.createSidStaff = function (registerInfo /*AuthSignUp*/) {
        var _this = this;
        this.isUploading = true;
        this.isLoadingListener.next(true);
        var loginInfo = {
            username: this.helpService.lowerCase(registerInfo.username),
            password: registerInfo.password,
        };
        var regInfo = {
            username: this.helpService.lowerCase(registerInfo.username),
            first_name: this.helpService.firstUpper(registerInfo.first_name),
            last_name: this.helpService.upperCase(registerInfo.last_name),
            email: this.helpService.lowerCase(registerInfo.email),
            password: registerInfo.password,
            groups: [],
        };
        var updateInfo = {
            sex: registerInfo.sex,
            phone: registerInfo.phone,
            address: registerInfo.address,
            birth_date: null,
            // birth_date: this.helpService.formatDate(registerInfo.birth_date),
            // bank_code: "access",
            bank_code: registerInfo.bank_code,
            city: registerInfo.city,
            country: registerInfo.country,
            postal_code: registerInfo.postal_code,
            account_number: registerInfo.account_number,
            account_code: registerInfo.account_code,
            // user_type: registerInfo.user_type,
            user_type: "BNK",
            profile_status: true,
        };
        // console.log({ ...regInfo, ...updateInfo });
        this.http
            .post(this.apiUrl + "auth/sid_staff/register/", regInfo)
            // .subscribe((res) => {
            //   // console.log(res);
            // });
            .pipe(switchMap(function (res) {
            // console.log(res);
            _this.id = res.id;
            return _this.http.patch(_this.apiUrl + "app/users/" + _this.id + "/", updateInfo);
        }))
            .subscribe(function (res) {
            _this.isUploading = false;
            _this.isLoadingListener.next(false);
            // console.log(res);
            _this.notification.success(":: Succesfully Created account");
            // this.login(loginInfo);
            _this.router.navigate(["/dashboard"]);
            _this.isUploading = false;
            // registerInfo.reset();
            // this.router.navigate(["complete-registration/"]);
        }, function (err) {
            switch (err.status) {
                case 400: {
                    _this.notification.warn(ERROR_MESSAGE_DICTIONARY.createUserE400);
                    break;
                }
                case 401: {
                    _this.notification.warn(ERROR_MESSAGE_DICTIONARY.createUserE401);
                    break;
                }
                case 403: {
                    _this.notification.warn(ERROR_MESSAGE_DICTIONARY.createUserE403);
                    break;
                }
                case 404: {
                    _this.notification.warn(ERROR_MESSAGE_DICTIONARY.createUserE404);
                    break;
                }
                case 415: {
                    _this.notification.warn(ERROR_MESSAGE_DICTIONARY.createUserE415);
                    break;
                }
                case 500: {
                    _this.notification.warn(ERROR_MESSAGE_DICTIONARY.e500);
                    break;
                }
                case 503: {
                    _this.notification.warn(ERROR_MESSAGE_DICTIONARY.e503);
                    break;
                }
            }
            _this.isUploading = false;
        });
    };
    UserService.prototype.createMgtAdmin = function (registerInfo /*AuthSignUp*/) {
        var _this = this;
        this.isUploading = true;
        this.isLoadingListener.next(true);
        var loginInfo = {
            username: this.helpService.lowerCase(registerInfo.username),
            password: registerInfo.password,
        };
        var regInfo = {
            username: this.helpService.lowerCase(registerInfo.username),
            first_name: this.helpService.firstUpper(registerInfo.first_name),
            last_name: this.helpService.upperCase(registerInfo.last_name),
            email: this.helpService.lowerCase(registerInfo.email),
            password: registerInfo.password,
            groups: [],
        };
        var updateInfo = {
            sex: registerInfo.sex,
            phone: registerInfo.phone,
            address: registerInfo.address,
            birth_date: null,
            // birth_date: this.helpService.formatDate(registerInfo.birth_date),
            // bank_code: "access",
            bank_code: registerInfo.bank_code,
            city: registerInfo.city,
            country: registerInfo.country,
            postal_code: registerInfo.postal_code,
            account_number: registerInfo.account_number,
            account_code: registerInfo.account_code,
            // user_type: registerInfo.user_type,
            user_type: "BNK",
            profile_status: true,
        };
        // console.log({ ...regInfo, ...updateInfo });
        this.http
            .post(this.apiUrl + "auth/sida/register/", regInfo)
            // .subscribe((res) => {
            //   // console.log(res);
            // });
            .pipe(switchMap(function (res) {
            // console.log(res);
            _this.id = res.id;
            return _this.http.patch(_this.apiUrl + "app/users/" + _this.id + "/", updateInfo);
        }))
            .subscribe(function (res) {
            _this.isUploading = false;
            _this.isLoadingListener.next(false);
            // console.log(res);
            _this.notification.success(":: Succesfully Created account");
            // this.login(loginInfo);
            _this.router.navigate(["/dashboard"]);
            _this.isUploading = false;
            // registerInfo.reset();
            // this.router.navigate(["complete-registration/"]);
        }, function (err) {
            switch (err.status) {
                case 400: {
                    _this.notification.warn(ERROR_MESSAGE_DICTIONARY.createUserE400);
                    break;
                }
                case 401: {
                    _this.notification.warn(ERROR_MESSAGE_DICTIONARY.createUserE401);
                    break;
                }
                case 403: {
                    _this.notification.warn(ERROR_MESSAGE_DICTIONARY.createUserE403);
                    break;
                }
                case 404: {
                    _this.notification.warn(ERROR_MESSAGE_DICTIONARY.createUserE404);
                    break;
                }
                case 415: {
                    _this.notification.warn(ERROR_MESSAGE_DICTIONARY.createUserE415);
                    break;
                }
                case 500: {
                    _this.notification.warn(ERROR_MESSAGE_DICTIONARY.e500);
                    break;
                }
                case 503: {
                    _this.notification.warn(ERROR_MESSAGE_DICTIONARY.e503);
                    break;
                }
            }
            _this.isUploading = false;
        });
    };
    UserService.prototype.sidCreateDelivery = function (registerInfo) {
        var _this = this;
        this.isUploading = true;
        this.isLoadingListener.next(true);
        var regInfo = {
            username: this.helpService.lowerCase(registerInfo.username),
            first_name: this.helpService.firstUpper(registerInfo.first_name),
            last_name: this.helpService.upperCase(registerInfo.last_name),
            email: this.helpService.lowerCase(registerInfo.email),
            password: registerInfo.password,
            groups: [],
            courier: registerInfo.courier,
        };
        var updateInfo = {
            // sex: registerInfo.sex,
            phone: registerInfo.phone,
            address: registerInfo.address,
            birth_date: null,
            // birth_date: this.helpService.formatDate(registerInfo.birth_date),
            // bank_code: "access",
            // bank_code: registerInfo.bank_code,
            city: registerInfo.city,
            country: registerInfo.country,
            postal_code: registerInfo.postal_code,
            // account_number: registerInfo.account_number,
            // account_code: registerInfo.account_code,
            // user_type: registerInfo.user_type,
            user_type: "DVY",
            profile_status: true,
        };
        // console.log({ ...regInfo, ...updateInfo });
        this.http
            .post(this.apiUrl + "auth/delivery_agent/register/", regInfo)
            // .subscribe((res) => {
            //   // console.log(res);
            // });
            .pipe(switchMap(function (res) {
            // console.log(res);
            _this.id = res.id;
            return _this.http.patch(_this.apiUrl + "app/users/" + _this.id + "/", updateInfo);
        }))
            .subscribe(function (res) {
            _this.isUploading = false;
            _this.isLoadingListener.next(false);
            // console.log(res);
            _this.notification.success(":: Succesfully Created account");
            // this.login(loginInfo);
            _this.router.navigate(["/couriers/delivery-agent-list"]);
            _this.isUploading = false;
            // registerInfo.reset();
            // this.router.navigate(["complete-registration/"]);
        }, function (err) {
            switch (err.status) {
                case 400: {
                    _this.notification.warn(ERROR_MESSAGE_DICTIONARY.createUserE400);
                    break;
                }
                case 401: {
                    _this.notification.warn(ERROR_MESSAGE_DICTIONARY.createUserE401);
                    break;
                }
                case 403: {
                    _this.notification.warn(ERROR_MESSAGE_DICTIONARY.createUserE403);
                    break;
                }
                case 404: {
                    _this.notification.warn(ERROR_MESSAGE_DICTIONARY.createUserE404);
                    break;
                }
                case 415: {
                    _this.notification.warn(ERROR_MESSAGE_DICTIONARY.createUserE415);
                    break;
                }
                case 500: {
                    _this.notification.warn(ERROR_MESSAGE_DICTIONARY.e500);
                    break;
                }
                case 503: {
                    _this.notification.warn(ERROR_MESSAGE_DICTIONARY.e503);
                    break;
                }
            }
            _this.isUploading = false;
        });
    };
    UserService.prototype.createDelivery = function (registerInfo) {
        var _this = this;
        this.isUploading = true;
        this.isLoadingListener.next(true);
        var regInfo = {
            username: this.helpService.lowerCase(registerInfo.username),
            first_name: this.helpService.firstUpper(registerInfo.first_name),
            last_name: this.helpService.upperCase(registerInfo.last_name),
            email: this.helpService.lowerCase(registerInfo.email),
            password: registerInfo.password,
            groups: [],
        };
        var updateInfo = {
            // sex: registerInfo.sex,
            phone: registerInfo.phone,
            address: registerInfo.address,
            birth_date: null,
            // birth_date: this.helpService.formatDate(registerInfo.birth_date),
            // bank_code: "access",
            // bank_code: registerInfo.bank_code,
            city: registerInfo.city,
            country: registerInfo.country,
            postal_code: registerInfo.postal_code,
            // account_number: registerInfo.account_number,
            // account_code: registerInfo.account_code,
            // user_type: registerInfo.user_type,
            user_type: "Oth",
            is_staff: "Dvy",
            profile_status: true,
        };
        // console.log({ ...regInfo, ...updateInfo });
        this.http
            .post(this.apiUrl + "auth/delivery_agent/register/", regInfo)
            // .subscribe((res) => {
            //   // console.log(res);
            // });
            .pipe(switchMap(function (res) {
            // console.log(res);
            _this.id = res.id;
            return _this.http.patch(_this.apiUrl + "app/users/" + _this.id + "/", updateInfo);
        }))
            .subscribe(function (res) {
            _this.isUploading = false;
            _this.isLoadingListener.next(false);
            // console.log(res);
            _this.notification.success(":: Succesfully Created account");
            // this.login(loginInfo);
            _this.router.navigate(["/couriers/delivery-agent-list"]);
            _this.isUploading = false;
            // registerInfo.reset();
            // this.router.navigate(["complete-registration/"]);
        }, function (err) {
            switch (err.status) {
                case 400: {
                    _this.notification.warn(ERROR_MESSAGE_DICTIONARY.createUserE400);
                    break;
                }
                case 401: {
                    _this.notification.warn(ERROR_MESSAGE_DICTIONARY.createUserE401);
                    break;
                }
                case 403: {
                    _this.notification.warn(ERROR_MESSAGE_DICTIONARY.createUserE403);
                    break;
                }
                case 404: {
                    _this.notification.warn(ERROR_MESSAGE_DICTIONARY.createUserE404);
                    break;
                }
                case 415: {
                    _this.notification.warn(ERROR_MESSAGE_DICTIONARY.createUserE415);
                    break;
                }
                case 500: {
                    _this.notification.warn(ERROR_MESSAGE_DICTIONARY.e500);
                    break;
                }
                case 503: {
                    _this.notification.warn(ERROR_MESSAGE_DICTIONARY.e503);
                    break;
                }
            }
            _this.isUploading = false;
        });
    };
    UserService.prototype.isEmailTaken = function (email) {
        return this.http.get("assets/users.json").pipe(
        // delay(1000),
        // map(res => res.json()),
        map(function (users) { return users.filter(function (user) { return user.email === email; }); }), map(function (users) { return !users.length; }));
    };
    UserService.prototype.isUsernameTaken = function (email) {
        return this.http.get("assets/users.json").pipe(
        // delay(1000),
        map(function (res) { return res; })
        // map((users: any[]) => users.filter(user => user.email === email)),
        // map((users: any[]) => !users.length)
        );
    };
    UserService.prototype.getStaffList = function (limit, page) {
        var _this = this;
        if (limit === void 0) { limit = 10; }
        if (page === void 0) { page = 1; }
        var query = "?limit=" + limit + "&page=" + page;
        this.http
            .get(this.apiUrl + "app/all/sid_users/" + query)
            .subscribe(function (res) {
            // console.log(res);
            res.result.length ? (_this.staff = res) : (_this.staff.result = []);
            _this.staffUpdate.next(__assign({}, _this.staff));
        }, function (err) {
            switch (err.status) {
                case 400: {
                    _this.notification.warn(ERROR_MESSAGE_DICTIONARY.e400);
                    break;
                }
                case 401: {
                    _this.notification.warn(ERROR_MESSAGE_DICTIONARY.e401);
                    break;
                }
                case 403: {
                    _this.notification.warn(ERROR_MESSAGE_DICTIONARY.e403);
                    break;
                }
                case 404: {
                    _this.notification.warn(ERROR_MESSAGE_DICTIONARY.e404);
                    break;
                }
                case 415: {
                    _this.notification.warn(ERROR_MESSAGE_DICTIONARY.e415);
                    break;
                }
                case 500: {
                    _this.notification.warn(ERROR_MESSAGE_DICTIONARY.e500);
                    break;
                }
                case 503: {
                    _this.notification.warn(ERROR_MESSAGE_DICTIONARY.e503);
                    break;
                }
            }
            // this.isUploading = false;
        });
    };
    UserService.prototype.getStaffUpdate = function () {
        return this.staffUpdate.asObservable();
    };
    UserService.prototype.toggleStaff = function (staff) {
        var _this = this;
        this.logger.log(staff);
        // // console.log(staff);
        this.http
            .post(this.apiUrl + "app/user/activate_deactivate/", staff)
            .subscribe(function (data) {
            staff.type
                ? _this.notification.success("User Activated")
                : _this.notification.warn("user Deactivated");
            var updatedStaffList = _this.staff.result.slice();
            var oldStaff = updatedStaffList.find(function (s) { return s.user_id === staff.user_id; });
            oldStaff.status = staff.type;
            var oldStaffIndex = updatedStaffList.findIndex(function (s) { return s.user_id === staff.user_id; });
            updatedStaffList[oldStaffIndex] = oldStaff;
            _this.staffUpdate.next(__assign({}, _this.staff));
        }, function (err) {
            _this.logger.error(err);
            switch (err.status) {
                case 400: {
                    _this.notification.warn(ERROR_MESSAGE_DICTIONARY.createUserE400);
                    break;
                }
                case 401: {
                    _this.notification.warn(ERROR_MESSAGE_DICTIONARY.createUserE401);
                    break;
                }
                case 403: {
                    _this.notification.warn(ERROR_MESSAGE_DICTIONARY.createUserE403);
                    break;
                }
                case 404: {
                    _this.notification.warn(ERROR_MESSAGE_DICTIONARY.createUserE404);
                    break;
                }
                case 415: {
                    _this.notification.warn(ERROR_MESSAGE_DICTIONARY.createUserE415);
                    break;
                }
                case 500: {
                    _this.notification.warn(ERROR_MESSAGE_DICTIONARY.e500);
                    break;
                }
                case 503: {
                    _this.notification.warn(ERROR_MESSAGE_DICTIONARY.e503);
                    break;
                }
            }
            // this.isUploading = false;
        });
    };
    UserService.ngInjectableDef = i0.defineInjectable({ factory: function UserService_Factory() { return new UserService(i0.inject(i1.HelperService), i0.inject(i2.NotificationService), i0.inject(i3.LogService), i0.inject(i4.Router), i0.inject(i5.HttpClient), i0.inject(i6.CompanyService)); }, token: UserService, providedIn: "root" });
    return UserService;
}());
export { UserService };
