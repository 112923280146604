import { Injectable } from "@angular/core";
import { HttpClient } from "@angular/common/http";
import { HelperService } from "./helper.service";
import { Subject, Observable } from "rxjs";
import { environment } from "src/environments/environment";
import { ERROR_MESSAGE_DICTIONARY } from "./error-message";
import { NotificationService } from "./notification.service";
import { Router } from "@angular/router";
import {
  debounceTime,
  distinctUntilChanged,
  switchMap,
  map,
} from "rxjs/operators";

@Injectable({
  providedIn: "root",
})
export class SortingService {
  private assignLoading = new Subject<boolean>();
  private triggerLoading = new Subject<boolean>();
  private excelUploading = new Subject<boolean>();
  private sortingList: { items: number; pages: number; result: any[] };
  private sortingListUpdate = new Subject<{
    items: number;
    pages: number;
    result: any[];
  }>();

  private apiUrl = environment.apiUrl;
  private package: any;
  private packageUpdate = new Subject<any>();

  constructor(
    private http: HttpClient,
    private helperService: HelperService,
    private notification: NotificationService,
    private router: Router
  ) { }

  getSortingList(
    start_date = this.helperService.formatDate(new Date()),
    end_date = this.helperService.formatDate(new Date()),
    limit = 10,
    page = 1,
    courier_id = 0
  ) {
    const query = `?limit=${limit}&page=${page}`;
    const data = {
      start_date,
      end_date,
      courier_id, //or <id>  (0- show all job with all status OR <status_id> - jobs per specific status)
      // bank_code: "all", //or "access"  ("all" - show all job for all banks OR "access" - jobs per specific bank(access bank))
    };
    this.http
      .post<{ items: number; pages: number; result: any[] }>(
        `${this.apiUrl}app/search/sorting_list/range/${query}`,
        data
      )
      .subscribe(
        (res) => {
          // console.log(res);
          this.sortingList = res;
          this.sortingListUpdate.next({ ...this.sortingList });
        },
        (err: any) => {
          // console.log(err.error);
          switch (err.status) {
            case 400: {
              this.notification.warn(ERROR_MESSAGE_DICTIONARY.e400);
              break;
            }
            case 401: {
              this.notification.warn(ERROR_MESSAGE_DICTIONARY.e401);
              break;
            }
            case 403: {
              this.notification.warn(ERROR_MESSAGE_DICTIONARY.e403);
              break;
            }
            case 404: {
              this.notification.warn(ERROR_MESSAGE_DICTIONARY.e404);
              break;
            }
            case 415: {
              this.notification.warn(ERROR_MESSAGE_DICTIONARY.e415);
              break;
            }
            case 500: {
              this.notification.warn(ERROR_MESSAGE_DICTIONARY.e500);
              break;
            }
            case 503: {
              this.notification.warn(ERROR_MESSAGE_DICTIONARY.e503);
              break;
            }
          }
          // this.isUploading = false;
        }
      );
  }

  assignJob(job) {
    // app/job_file/
    this.assignLoading.next(true);
    this.http
      .patch(`${this.apiUrl}app/job_file/${job.id}/`, {
        assigned_agent: job.assigned_agent,
      })
      .subscribe(
        (res) => {
          this.assignLoading.next(false);
          // console.log(res);
          this.router.navigate(["/dashboard"]);
          this.notification.success(`Job Assigned`);
          // this.sortingList = res;
          // this.sortingListUpdate.next({ ...this.sortingList });
        },
        (err: any) => {
          // console.log(err.error);
          switch (err.status) {
            case 400: {
              this.notification.warn(ERROR_MESSAGE_DICTIONARY.e400);
              break;
            }
            case 401: {
              this.notification.warn(ERROR_MESSAGE_DICTIONARY.e401);
              break;
            }
            case 403: {
              this.notification.warn(ERROR_MESSAGE_DICTIONARY.e403);
              break;
            }
            case 404: {
              this.notification.warn(ERROR_MESSAGE_DICTIONARY.e404);
              break;
            }
            case 415: {
              this.notification.warn(ERROR_MESSAGE_DICTIONARY.e415);
              break;
            }
            case 500: {
              this.notification.warn(ERROR_MESSAGE_DICTIONARY.e500);
              break;
            }
            case 503: {
              this.notification.warn(ERROR_MESSAGE_DICTIONARY.e503);
              break;
            }
          }
          // this.isUploading = false;
        }
      );
  }

  returnJob(job) {
    // app/job_file/
    const returned_user = this.helperService.getUser();
    job.returned_user = returned_user;
    this.assignLoading.next(true);
    this.http
      .patch(`${this.apiUrl}app/job_file/${job.id}/`, {
        delivery_status: job.delivery_status,
        job_status: job.job_status,
        returned_user,
      })
      .subscribe(
        (res) => {
          // console.log(res)
          this.assignLoading.next(false);
          // const list = [...this.sortingList.result]
          // const oldSortIndex = list.findIndex((sort) => {sort.id === job.id})
          // list[oldSortIndex] = job;
          // this.sortingList.result = list;
          // this.sortingListUpdate.next({ ...this.sortingList });
          // this.notification.success(`Job Returned`);
          // this.sortingList = res;
          this.router.navigate(["/dashboard"]);
        },
        (err: any) => {
          // console.log(err.error);
          switch (err.status) {
            case 400: {
              this.notification.warn(ERROR_MESSAGE_DICTIONARY.e400);
              break;
            }
            case 401: {
              this.notification.warn(ERROR_MESSAGE_DICTIONARY.e401);
              break;
            }
            case 403: {
              this.notification.warn(ERROR_MESSAGE_DICTIONARY.e403);
              break;
            }
            case 404: {
              this.notification.warn(ERROR_MESSAGE_DICTIONARY.e404);
              break;
            }
            case 415: {
              this.notification.warn(ERROR_MESSAGE_DICTIONARY.e415);
              break;
            }
            case 500: {
              this.notification.warn(ERROR_MESSAGE_DICTIONARY.e500);
              break;
            }
            case 503: {
              this.notification.warn(ERROR_MESSAGE_DICTIONARY.e503);
              break;
            }
          }
          // this.isUploading = false;
        }
      );
  }

  cancelJob(job) {
    // app/cancel-job/
    // const returned_user = this.helperService.getUser();
    // job.returned_user = returned_user;
    this.assignLoading.next(true);
    this.http
      .patch(`${this.apiUrl}app/cancel-job/${job.id}/`, {
        delivery_status: job.delivery_status,
        job_status: job.job_status,
        // returned_user,
      })
      .subscribe(
        (res) => {
          // console.log(res)
          this.assignLoading.next(false);
          // const list = [...this.sortingList.result]
          // const oldSortIndex = list.findIndex((sort) => {sort.id === job.id})
          // list[oldSortIndex] = job;
          // this.sortingList.result = list;
          // this.sortingListUpdate.next({ ...this.sortingList });
          // this.notification.success(`Job Returned`);
          // this.sortingList = res;
          this.router.navigate(["/dashboard"]);
        },
        (err: any) => {
          this.notification.warn(err.error.message);
          // console.log(err.error);
          switch (err.status) {
            case 400: {
              this.notification.warn(ERROR_MESSAGE_DICTIONARY.e400);
              break;
            }
            case 401: {
              this.notification.warn(ERROR_MESSAGE_DICTIONARY.e401);
              break;
            }
            case 403: {
              this.notification.warn(ERROR_MESSAGE_DICTIONARY.e403);
              break;
            }
            case 404: {
              this.notification.warn(ERROR_MESSAGE_DICTIONARY.e404);
              break;
            }
            case 406: {
              this.notification.warn(err.error.message);
              break;
            }
            case 415: {
              this.notification.warn(ERROR_MESSAGE_DICTIONARY.e415);
              break;
            }
            case 500: {
              this.notification.warn(ERROR_MESSAGE_DICTIONARY.e500);
              break;
            }
            case 503: {
              this.notification.warn(ERROR_MESSAGE_DICTIONARY.e503);
              break;
            }
          }
          // this.isUploading = false;
        }
      );
  }

  uploadImage(job, form) {
    // app/job_file/
    this.assignLoading.next(true);
    this.http.patch(`${this.apiUrl}app/job_file/${job}/`, form).subscribe(
      (res) => {
        this.assignLoading.next(false);
        // console.log(res);
        this.notification.success(`Note Uploaded`);
        // this.sortingList = res;
        // this.sortingListUpdate.next({ ...this.sortingList });
      },
      (err: any) => {
        // console.log(err.error);
        switch (err.status) {
          case 400: {
            this.notification.warn(ERROR_MESSAGE_DICTIONARY.e400);
            break;
          }
          case 401: {
            this.notification.warn(ERROR_MESSAGE_DICTIONARY.e401);
            break;
          }
          case 403: {
            this.notification.warn(ERROR_MESSAGE_DICTIONARY.e403);
            break;
          }
          case 404: {
            this.notification.warn(ERROR_MESSAGE_DICTIONARY.e404);
            break;
          }
          case 415: {
            this.notification.warn(ERROR_MESSAGE_DICTIONARY.e415);
            break;
          }
          case 500: {
            this.notification.warn(ERROR_MESSAGE_DICTIONARY.e500);
            break;
          }
          case 503: {
            this.notification.warn(ERROR_MESSAGE_DICTIONARY.e503);
            break;
          }
        }
        // this.isUploading = false;
      }
    );
  }

  getAssignLoading() {
    return this.assignLoading.asObservable();
  }

  getTriggerLoading() {
    return this.triggerLoading.asObservable();
  }

  getBankSortingList(
    start_date = this.helperService.formatDate(new Date()),
    end_date = this.helperService.formatDate(new Date()),
    // code_id = 1,
    limit = 10,
    page = 1,
    courier_id = 0
  ) {
    const query = `?limit=${limit}&page=${page}`;
    const data = {
      start_date,
      end_date,
      code_id: this.helperService.getBankCode(),
      status_id: 0,
      courier_id, //or <id>  (0- show all job with all status OR <status_id> - jobs per specific status)
      // bank_code: "all", //or "access"  ("all" - show all job for all banks OR "access" - jobs per specific bank(access bank))
    };
    // console.log(data);
    this.http
      .post<{ items: number; pages: number; result: any[] }>(
        `${this.apiUrl}app/bnk/sorting_list/range/${query}`,
        data
      )
      .subscribe(
        (res) => {
          // console.log(res);
          this.sortingList = res;
          this.sortingListUpdate.next({ ...this.sortingList });
        },
        (err: any) => {
          switch (err.status) {
            case 400: {
              this.notification.warn(ERROR_MESSAGE_DICTIONARY.e400);
              break;
            }
            case 401: {
              this.notification.warn(ERROR_MESSAGE_DICTIONARY.e401);
              break;
            }
            case 403: {
              this.notification.warn(ERROR_MESSAGE_DICTIONARY.e403);
              break;
            }
            case 404: {
              this.notification.warn(ERROR_MESSAGE_DICTIONARY.e404);
              break;
            }
            case 415: {
              this.notification.warn(ERROR_MESSAGE_DICTIONARY.e415);
              break;
            }
            case 500: {
              this.notification.warn(ERROR_MESSAGE_DICTIONARY.e500);
              break;
            }
            case 503: {
              this.notification.warn(ERROR_MESSAGE_DICTIONARY.e503);
              break;
            }
          }
          // this.isUploading = false;
        }
      );
  }

  getCourierSortingList(
    start_date = this.helperService.formatDate(new Date()),
    end_date = this.helperService.formatDate(new Date()),
    limit = 10,
    page = 1
    // courier_id = 0
  ) {
    const query = `?limit=${limit}&page=${page}`;
    const data = {
      start_date,
      end_date,
      code_id: this.helperService.getBankCode(),
      status_id: 0,
      // courier_id, //or <id>  (0- show all job with all status OR <status_id> - jobs per specific status)
      // bank_code: "all", //or "access"  ("all" - show all job for all banks OR "access" - jobs per specific bank(access bank))
    };
    // console.log(data);
    this.http
      .post<{ items: number; pages: number; result: any[] }>(
        `${this.apiUrl}app/search/sorting_list/secure_id/${query}`,
        data
      )
      .subscribe(
        (res) => {
          console.log(res);
          this.sortingList = res;
          this.sortingListUpdate.next({ ...this.sortingList });
        },
        (err: any) => {
          switch (err.status) {
            case 400: {
              this.notification.warn(ERROR_MESSAGE_DICTIONARY.e400);
              break;
            }
            case 401: {
              this.notification.warn(ERROR_MESSAGE_DICTIONARY.e401);
              break;
            }
            case 403: {
              this.notification.warn(ERROR_MESSAGE_DICTIONARY.e403);
              break;
            }
            case 404: {
              this.notification.warn(ERROR_MESSAGE_DICTIONARY.e404);
              break;
            }
            case 415: {
              this.notification.warn(ERROR_MESSAGE_DICTIONARY.e415);
              break;
            }
            case 500: {
              this.notification.warn(ERROR_MESSAGE_DICTIONARY.e500);
              break;
            }
            case 503: {
              this.notification.warn(ERROR_MESSAGE_DICTIONARY.e503);
              break;
            }
          }
          // this.isUploading = false;
        }
      );
  }

  searchByJobId(jobID: Observable<string>) {
    return jobID.pipe(
      debounceTime(400),
      distinctUntilChanged(),
      switchMap((term) => this.searchEntries(term))
    );
  }

  searchEntries(term) {
    return this.http
      .post(`${this.apiUrl}app/search/sorting_list/job_id/`, { job_id: term })
      .pipe(map((res: any[]) => res));
  }

  searchForBankByJobId(jobID: Observable<string>) {
    return jobID.pipe(
      debounceTime(400),
      distinctUntilChanged(),
      switchMap((term) => this.searchBankEntries(term))
    );
  }

  searchBankEntries(term) {
    return this.http
      .post(`${this.apiUrl}app/bnk/sorting_list/job_id/`, {
        job_id: term,
        code_id: this.helperService.getBankCode(),
      })
      .pipe(map((res: any[]) => res));
  }

  getSortingListUpdate() {
    return this.sortingListUpdate.asObservable();
  }

  uploadSortingList(list, courier_id) {
    this.excelUploading.next(true);
    // console.log(list);
    // this.http.post(`${this.apiUrl}app/add/sorting_array/`, list).subscribe(
    this.http
      .post(`${this.apiUrl}app/all/upload/sorting_list/`, list)
      .subscribe(
        (res) => {
          this.excelUploading.next(false);
          // form.reset();
          // console.log(res);
          this.triggerDHL(Number(courier_id));
          this.notification.success("Submitted");
          this.router.navigate(["/sort-list"]);
        },
        (err: any) => {
          this.excelUploading.next(false);
          // console.log(err.error);
          switch (err.status) {
            case 400: {
              this.notification.warn(ERROR_MESSAGE_DICTIONARY.e400);
              break;
            }
            case 401: {
              this.notification.warn(ERROR_MESSAGE_DICTIONARY.e401);
              break;
            }
            case 403: {
              this.notification.warn(ERROR_MESSAGE_DICTIONARY.e403);
              break;
            }
            case 404: {
              this.notification.warn(ERROR_MESSAGE_DICTIONARY.e404);
              break;
            }
            case 415: {
              this.notification.warn(ERROR_MESSAGE_DICTIONARY.e415);
              break;
            }
            case 500: {
              this.notification.warn(ERROR_MESSAGE_DICTIONARY.e500);
              break;
            }
            case 503: {
              this.notification.warn(ERROR_MESSAGE_DICTIONARY.e503);
              break;
            }
          }
          // this.isUploading = false;
        }
      );
  }

  getExcelUploading() {
    return this.excelUploading.asObservable();
  }

  getDetailsByTrackingID(track_id) {
    this.http
      .post(`${this.apiUrl}app/get/tracking_details/tracking_id/`, {
        track_id,
      })
      .subscribe((data) => {
        // console.log(data);
        this.package = data;
        this.packageUpdate.next(this.package);
      });
  }

  getPackageUpdate() {
    return this.packageUpdate.asObservable();
  }

  getSIDPackageDetailsByRef(ref_id) {
    this.http
      .post(`${this.apiUrl}app/track/sid_jobs/`, {
        ref_id,
      })
      .subscribe((data) => {
        // console.log(data);
        this.package = data;
        this.packageUpdate.next(this.package);
      });
  }

  // getPackageUpdate() {
  //   return this.packageUpdate.asObservable();
  // }

  triggerDHL(courier_id) {
    this.triggerLoading.next(true);
    this.http
      .post(`${this.apiUrl}app/create/tracking_id/`, { id: courier_id })
      .subscribe((res) => {
        this.triggerLoading.next(false);
        this.notification.success(`Courier triggered`);
        // console.log(res);
      });
  }

  getSeal(job_id) {
    // console.log(job_id);
    return this.http.get(`${this.apiUrl}app/job_file/${job_id}/`);
  }
}
